//关于
const About = () =>
  import(/* webpackChunkName:'About' */ "@/views/about/index");
//服务协议
const Condition = () =>
  import(/* webpackChunkName:'Condition' */ "@/views/about/condition");
//隐私政策
const Privacy = () =>
  import(/* webpackChunkName:'Privacy' */ "@/views/about/privacy");
//隐私政策
const PrivacyPolicy = () =>
  import(
    /* webpackChunkName:'aboutPrivacyPolicy' */ "@/views/about/privacy-policy"
  );
//隐私政策
const Security = () =>
  import(/* webpackChunkName:'Security' */ "@/views/about/security");

//免责声明
const Disclaimer = () =>
  import(/* webpackChunkName:'aboutDisclaimer' */ "@/views/about/disclaimer");
//客户协议书
const Agreement = () =>
  import(/* webpackChunkName:'PcTotalFees' */ "@/views/about/agreement");
//客户声明
const ClientStatement = () =>
  import(
    /* webpackChunkName:'ClientStatement' */ "@/views/about/client-statement"
  );
//常设授权书（客户款项及客户证券）
const StandingAuthority = () =>
  import(
    /* webpackChunkName:'StandingAuthority' */ "@/views/about/standing-authority"
  );
//税务自我证明声明
const TaxStatement = () =>
  import(/* webpackChunkName:'TaxStatement' */ "@/views/about/tax-statement");
//注册协议
const TaxRegister = () =>
  import(/* webpackChunkName:'TaxRegister' */ "@/views/about/tax-register");
//风险披露声明
const RiskDisclosure = () =>
  import(/* webpackChunkName:'PcTotalFees' */ "@/views/about/risk-disclosure");

//清科证券收费表
const FeeTable = () =>
  import(/* webpackChunkName:'FeeTable' */ "@/views/about/fee-table");

const route = {
  path: "/about",
  component: About,
  redirect: "/about/agreement",
  meta: {
    title: "",
  },
  children: [
    {
      name: "Condition",
      path: "/about/condition",
      component: Condition,
      meta: {
        title: "服务协议",
      },
    },
    {
      name: "aAgreement",
      path: "/about/agreement",
      component: Agreement,
      meta: {
        title: "服务协议",
      },
    },
    {
      name: "Privacy",
      path: "/about/privacy",
      component: Privacy,
      meta: {
        title: "隐私政策",
      },
    },
    {
      name: "Security",
      path: "/about/Security",
      component: Security,
      meta: {
        title: "网上安全",
      },
    },
    {
      name: "aboutPrivacyPolicy",
      path: "/about/privacy-policy",
      component: PrivacyPolicy,
      meta: {
        title: "隐私策略",
      },
    },

    {
      name: "aboutDisclaimer",
      path: "/about/disclaimer",
      component: Disclaimer,
      meta: {
        title: "免责声明",
      },
    },
    {
      name: "ClientStatement",
      path: "/about/client-statement",
      component: ClientStatement,
      meta: {
        title: "客户声明",
      },
    },
    {
      name: "StandingAuthority",
      path: "/about/standing-authority",
      component: StandingAuthority,
      meta: {
        title: "常设授权书（客户款项及客户证券）",
      },
    },
    {
      name: "TaxStatement",
      path: "/about/tax-statement",
      component: TaxStatement,
      meta: {
        title: "税务自我证明声明",
      },
    },
    {
      name: "TaxRegister",
      path: "/about/tax-register",
      component: TaxRegister,
      meta: {
        title: "注册协议",
      },
    },
    {
      name: "RiskDisclosure",
      path: "/about/risk-disclosure",
      component: RiskDisclosure,
      meta: {
        title: "风险披露声明",
      },
    },
    {
      name: "FeeTable",
      path: "/about/fee-table",
      component: FeeTable,
      meta: {
        title: "清科证券收费表",
      },
    },
  ],
};

export default route;
