export default {
    router_name: "註冊協議",
    title: "註冊協議",
    content: `
              <ol type="1" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; font-weight:bold; line-height:108%; margin:0pt 0pt 6pt 13.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt; font-weight:bold">確認和接受</span>
                  </li>
              </ol>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 31.5pt; text-indent:-31.5pt">
                  <span style="font-family:Aptos; font-size:11pt">1.1.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:宋体; font-size:11pt">本協議是用戶（以下有時也稱</span>
                  <span style="font-family:Aptos; font-size:11pt">“</span>
                  <span style="font-family:宋体; font-size:11pt; font-weight:bold">您</span>
                  <span style="font-family:Aptos; font-size:11pt">”</span>
                  <span style="font-family:宋体; font-size:11pt">）與清科證券有限公司（以下稱為</span>
                  <span style="font-family:Aptos; font-size:11pt">“</span>
                  <span style="font-family:宋体; font-size:11pt; font-weight:bold">清科</span>
                  <span style="font-family:Aptos; font-size:11pt">”</span>
                  <span style="font-family:宋体; font-size:11pt">或</span>
                  <span style="font-family:Aptos; font-size:11pt">“</span>
                  <span style="font-family:宋体; font-size:11pt; font-weight:bold">我們</span>
                  <span style="font-family:Aptos; font-size:11pt">”</span>
                  <span style="font-family:宋体; font-size:11pt">）之間關於使用</span>
                  <span style="font-family:Aptos; font-size:11pt">“</span>
                  <span style="font-family:宋体; font-size:11pt">清科證券</span>
                  <span style="font-family:Aptos; font-size:11pt">”APP</span>
                  <span style="font-family:宋体; font-size:11pt">（</span>
                  <span style="font-family:Aptos; font-size:11pt">“</span>
                  <span style="font-family:宋体; font-size:11pt; font-weight:bold">清科</span>
                  <span style="font-family:Aptos; font-size:11pt; font-weight:bold">APP</span>
                  <span style="font-family:Aptos; font-size:11pt">”</span>
                  <span style="font-family:宋体; font-size:11pt">）及其中所提供相關服務（</span>
                  <span style="font-family:Aptos; font-size:11pt">“</span>
                  <span style="font-family:宋体; font-size:11pt; font-weight:bold">本服務</span>
                  <span style="font-family:Aptos; font-size:11pt">”</span>
                  <span style="font-family:宋体; font-size:11pt">）所訂立的協議。本服務包括但不限於提供港股股票行情資訊流覽、查詢市場數據服務（以下簡稱</span>
                  <span style="font-family:Aptos; font-size:11pt">“</span>
                  <span style="font-family:宋体; font-size:11pt; font-weight:bold">市場數據服務</span>
                  <span style="font-family:Aptos; font-size:11pt">”</span>
                  <span style="font-family:宋体; font-size:11pt">）及股票交易管理等功能的平臺。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 31.5pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 31.5pt; text-indent:-31.5pt">
                  <span style="font-family:Aptos; font-size:11pt">1.2.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:宋体; font-size:11pt">在成為用戶前，您務必仔細閱讀以下條款，充分理解各條款內容後再選擇是否接受清科證券</span>
                  <span style="font-family:Aptos; font-size:11pt">APP</span>
                  <span style="font-family:宋体; font-size:11pt">用戶協議（以下簡稱</span>
                  <span style="font-family:Aptos; font-size:11pt">“</span>
                  <span style="font-family:宋体; font-size:11pt; font-weight:bold">本協議</span>
                  <span style="font-family:Aptos; font-size:11pt">”</span>
                  <span style="font-family:宋体; font-size:11pt">）。一旦您在清科提交用戶註冊申請，即意味著您已閱讀本協議所有條款，並對本協議條款的含義及相應的法律後果已全部通曉並充分理解，同意受本協議約束。同時，您在使用某特定服務時，該服務可能會另有單獨的協議、相關業務規則等（以下統稱為</span>
                  <span style="font-family:Aptos; font-size:11pt">“</span>
                  <span style="font-family:宋体; font-size:11pt; font-weight:bold">單獨協議</span>
                  <span style="font-family:Aptos; font-size:11pt">”</span>
                  <span style="font-family:宋体; font-size:11pt">），您在使用該項服務前請閱讀並同意相關的單獨協議。您使用前述特定服務，即視為您接受相關單獨協議。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 31.5pt; text-indent:-31.5pt">
                  <span style="font-family:Aptos; font-size:11pt">1.3.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:宋体; font-size:11pt">清科會不時修定本協議的內容並通過</span>
                  <span style="font-family:Aptos; font-size:11pt">https://qkintl.com.hk</span>
                  <span style="font-family:宋体; font-size:11pt">予以公佈。此外，清科可能不時發佈、更新的協議及各類規則、解釋、聲明、通告等亦為本協議不可分割的組成部分，與本協議正文具有同等的法律效力，您同樣應當遵守。若您繼續使用清科</span>
                  <span style="font-family:Aptos; font-size:11pt">APP</span>
                  <span style="font-family:宋体; font-size:11pt">或本服務，則表示您已知悉、同意並接受本協議的修改或變更。若您不同意，您應立即停用清科</span>
                  <span style="font-family:Aptos; font-size:11pt">APP</span>
                  <span style="font-family:宋体; font-size:11pt">及本服務。</span>
                  <span style="font-family:宋体; font-size:11pt"></span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 31.5pt; text-indent:-31.5pt">
                  <span style="font-family:Aptos; font-size:11pt">1.4.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:宋体; font-size:11pt">如您所屬的國家或地區排除本協議的全部或部分內容，您應立即停止使用相關服務，否則，您繼續使用清科</span>
                  <span style="font-family:Aptos; font-size:11pt">APP</span>
                  <span style="font-family:宋体; font-size:11pt">或本服務視為您同意承擔相關的法律風險或法律責任。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 31.5pt; text-indent:-31.5pt">
                  <span style="font-family:Aptos; font-size:11pt">1.5.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:宋体; font-size:11pt">若本協議任何條款因任何原因部分無效或不可執行，其餘條款仍有效，對您和清科均具有約束力。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <ol start="2" type="1" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; font-weight:bold; line-height:108%; margin:0pt 0pt 0pt 13.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt; font-weight:bold">協議簽署</span>
                      <br/>
                      <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
                  </li>
              </ol>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:11pt">2.1.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:宋体; font-size:11pt">用戶同意本協議內容並完成註冊程式後獲得一個帳號，即成為清科證券</span>
                  <span style="font-family:Aptos; font-size:11pt">APP</span>
                  <span style="font-family:宋体; font-size:11pt">的正式用戶。註冊成功即被視為用戶確認自己具有獨立的民事行為能力，明確自己所享有相應權利，能夠獨立承擔法律責任。在註冊或啟動帳號、對帳號進行實名認證及使用服務的過程中，您應向清科提供您合法、真實、有效及完整的資料。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:11pt">2.2.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:宋体; font-size:11pt">另外，請您妥善保管帳號資訊及密碼的安全。您需要對您的帳號及密碼下的行為承擔法律責任。當您懷疑有他人未經授權使用您的帳號等異常情形時，請立即通知清科。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:11pt">2.3.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:宋体; font-size:11pt">您有權按照清科規定的銷戶流程申請註銷帳號。如果您長期不登入賬號，清科有權回收該帳號，以免造成資源浪費，由此帶來的任何損失均由您自行承擔。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:11pt">2.4.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:宋体; font-size:11pt">您同意並授權清科在提供服務前和服務期間通過相關機關或組織查詢或核實您的相應資訊。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <ol start="3" type="1" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; font-weight:bold; line-height:108%; margin:6pt 0pt 0pt 13.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:Aptos; font-size:11pt; font-weight:bold">APP</span>
                      <span style="font-family:宋体; font-size:11pt; font-weight:bold">的接入和使用</span>
                   
                      <span style="font-family:宋体; font-size:11pt; font-weight:bold"></span>
                       <p style="font-family:Aptos; font-size:11pt; font-weight:normal; line-height:108%; list-style-position:inside; margin:10pt 0pt 0pt 18pt; text-indent:-36pt">
                           <span style="font-family:宋体; font-size:11pt;">3.1.</span>
                          <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                              <span style="font-family:Aptos; font-size:11pt">APP</span>
                              <span style="font-family:宋体; font-size:11pt">的許可</span>
                          </p>
                  </li>
              </ol>
              <ol type="a" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 48.63pt; padding-left:5.37pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">清科授權您一項有限的、可撤銷的、不可轉讓的、不可轉授權的及非排他性的使用清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">的許可。您僅可為訪問或使用本服務的目的而使用清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">。本協議未明示授權的其他一切權利仍由清科保留。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 49.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">本協議自你成功註冊之日起生效。</span>
                  </li>
              </ol>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 54pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Aptos; font-size:11pt">3.2.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:Aptos; font-size:11pt">APP</span>
                  <span style="font-family:宋体; font-size:11pt">的獲取、安裝及更新</span>
              </p>
              <ol type="a" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 48.63pt; padding-left:5.37pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">您可以直接從清科官網、軟體應用商店等管道下載清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">，也可以從得到清科授權的第三方獲取。若您從未經合法授權的第三方獲取清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">，清科無法保證該</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">的正常使用，亦不承擔由此造成的任何不利後果。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 49.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">為了提升用戶體驗、完善服務內容、保證服務的安全性，清科有權隨時對清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">進行更新或者對清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">的部分功能效果進行改變或限制。</span>
                  </li>
              </ol>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 54pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <ol start="4" type="1" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; font-weight:bold; line-height:108%; margin:0pt 0pt 0pt 13.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt; font-weight:bold">用戶注意事項及行為規範</span>
                      <ol type="1" class="awlist2" style="margin:0pt; padding-left:0pt">
                          <p style="font-family:Aptos; font-size:11pt; font-weight:normal; line-height:108%; list-style-position:inside; margin:10pt 0pt 0pt 18pt; text-indent:-36pt">
                           <span style="font-family:Aptos; font-size:11pt">4.1.</span>    
                          <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                              <span style="font-family:宋体; font-size:11pt">用戶注意事項</span>
                          </p>
                      </ol>
                  </li>
              </ol>
              <ol type="a" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 48.63pt; padding-left:5.37pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">為了向您提供有效的服務，清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">會利用您終端設備的處理器和帶寬等資源。清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">使用過程中可能產生數據流量的費用，您需自行向運營商瞭解相關資費資訊，並自行承擔相關費用。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 49.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">會盡其商業上的合理努力保障您在清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">中的數據存儲安全，但清科並不就此提供保證。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 48.63pt; padding-left:5.37pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">如果您停止使用清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">或帳號被回收，清科可以從伺服器上永久地刪除您的數據，清科並沒有義務向您返還任何數據。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 49.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">您通過清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">查詢到的證券資金資訊由清科提供，您的實際證券資金資訊仍以相應證券公司的記錄為准。如您對清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">上的證券資金資訊有異議，可直接聯繫該相應證券公司諮詢。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 48.63pt; padding-left:5.37pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">在任何情況下，您不應輕信借款、索要密碼或其他涉及財產的資訊。涉及財產操作的，請務必事前進行核實及確認，並請經常留意相關防範詐騙犯罪的提示。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 47.41pt; padding-left:6.59pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">如您通過清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">查詢他人在清科的證券資金資訊，您保證已經獲得了他人完整的授權。如您未獲得上述授權，而導致他人資產遭受損失的，由您承擔賠償責任。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 49.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">清科可能根據實際需要對收費服務的收費標準、方式進行修改和變更；前述修改、變更或開始收費前，清科將在相應服務頁面進行通知或公告。如果您不同意上述修改、變更或付費內容，則應停止使用該服務。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 49.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">您同意清科向您的手機號碼、電子電郵等聯繫方式傳送各類資訊，資訊傳送即視為您已收悉並瞭解相應內容。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 46.81pt; padding-left:7.19pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">針對用戶在清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">使用的“入金”、“出金”功能，清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">僅提供資訊諮詢、資訊核對的服務。轉賬、匯款需根據相應商業銀行的規則由您自行操作。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 46.81pt; padding-left:7.19pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">所展示的用戶證券資金資訊、股票持有、交易資訊僅是根據清科、交易所或其他數據來源所提供的數據進行即時展示。清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">在任何時候均不是用戶任何交易的提供方或擔保方。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 49.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">為保證服務品質，清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">提供客服電話服務。為保證服務品質，保障服務過程及內容的可追溯性，清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">保留在客服與用戶服務過程中進行全程錄音的權利，清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">承諾不主動將錄音內容洩露給第三方，但基於交易糾紛、技術原因及按法律要求等因素，清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">保留複製、審查錄音內容的權利。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 46.81pt; padding-left:7.19pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">可能會使用第三方軟體或技術，這種使用已經獲得合法授權。</span>
                  </li>
              </ol>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 54pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Aptos; font-size:11pt">4.2.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:宋体; font-size:11pt">用戶責任和義務</span>
              </p>
              <ol type="a" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 48.63pt; padding-left:5.37pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">用戶不得</span>
                      <span style="font-family:Aptos; font-size:11pt">:</span>
                  </li>
              </ol>
              <ol type="i" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 68pt; padding-left:13pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">利用清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">從事洗錢、竊取商業秘密、竊取其他用戶個人資訊等違法犯罪活動；</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 68pt; padding-left:13pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">侵入清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">傳播病毒、特洛依木馬等可能對清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">和清科的系統造成傷害或影響其正常運轉的惡意病毒或程式；及</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 68pt; padding-left:13pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">客戶不可從事非經清科同意的所有牟利性經營活動。</span>
                      <br/>
                      <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
                  </li>
              </ol>
              <ol start="2" type="a" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 49.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">若用戶未遵守以上約定，清科證券有權立即中止或終止對用戶提供服務，並採取一切必要措施，包括但不限於以下一項或多項：</span>
                  </li>
              </ol>
              <ol type="i" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 68pt; padding-left:13pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">警告違規用戶；</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 68pt; padding-left:13pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">變更、限制或禁止違規帳號部分或全部功能；</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 68pt; padding-left:13pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">暫停、限制或終止用戶使用清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">的權利、註銷用戶帳號等；及</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 68pt; padding-left:13pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">向有關監管部門或國家機關報告。</span>
                  </li>
              </ol>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 81pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <ol start="5" type="1" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; font-weight:bold; line-height:108%; margin:0pt 0pt 0pt 13.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt; font-weight:bold">市場數據服務特別約定</span>
                  </li>
              </ol>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:10pt">5.1</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:宋体; font-size:11pt">清科</span>
                  <span style="font-family:Aptos; font-size:11pt">APP</span>
                  <span style="font-family:宋体; font-size:11pt">提供的市場數據來自合作或對接的第三方市場數據服務商。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:10pt">5.2</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:宋体; font-size:11pt">您同意並確認本服務提供的市場數據僅供您個人在本協議明確的授權範圍內使用，不得用於其他任何目的；同時，您應採取一切合理措施，避免所接收的市場數據被用於其他未經授權或非法目的或被駭客入侵。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:10pt">5.3</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:宋体; font-size:11pt">您同意並確認未經市場數據服務商事先的書面同意，您不得向第三方以任何方式或格式將本協議項下的市場數據或其任何部分重新分配或以其他方式傳播。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:10pt">5.4</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:宋体; font-size:11pt">您知悉並確認，市場數據及其相關的全部權利均歸提供該市場數據的市場數據服務商所有。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:10pt">5.5</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:宋体; font-size:11pt">您確認並同意，任何未經授權使用產生的一切不利後果均由您自行承擔，包括但不限於應賠償清科或市場數據服務商的損失等。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:10pt">5.6</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:宋体; font-size:11pt">您確認並明白由於市場數據涉及金融資訊，因相關立法或任何有關規範的規定，市場數據服務商及／或清科保留相關權利調整、修改上述市場數據的內容、結構等。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:10pt">5.7</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:宋体; font-size:11pt">您確認及明白，清科須無條件的接受市場數據服務商不時提出的相關要求或因市場數據服務商的任何原因而調整或停止市場數據的提供。在發生這種情況下，清科在任何情況下均不對前述調整、中斷或終止承擔任何責任。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:10pt">5.8</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:宋体; font-size:11pt">因市場數據的內容、可靠性、完整性、全面性、準確性或其他品質問題而造成的任何直接或間接損害，清科均不會承擔直接或間接責任。同時，因市場數據傳輸導致的中斷或其他問題造成的任何損害，包括間接性損失、特殊、間接或懲罰性損害，清科亦不會承擔直接或間接責任。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:10pt">5.9</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:宋体; font-size:11pt">用戶如需使用清科</span>
                  <span style="font-family:Aptos; font-size:11pt">APP</span>
                  <span style="font-family:宋体; font-size:11pt">提供的高級行情流覽、查詢服務，應另行簽署協議支付對應費用。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:10pt">5.10</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:宋体; font-size:11pt">清科</span>
                  <span style="font-family:Aptos; font-size:11pt">APP</span>
                  <span style="font-family:宋体; font-size:11pt">有權調整高級行情流量、查詢服務所涉的計費方式和收費方式。用戶須遵守清科證券不時公佈的定價條款規則。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <ol start="6" type="1" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; font-weight:bold; line-height:108%; margin:0pt 0pt 0pt 13.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt; font-weight:bold">風險提示及免責聲明</span>
                  </li>
              </ol>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:11pt">6.1.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:宋体; font-size:11pt">風險提示</span>
                  <span style="font-family:宋体; font-size:11pt"></span>
              </p>
              <ol type="a" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 48.63pt; padding-left:5.37pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">股市有風險，投資需謹慎。任何一支股票的歷史數據都不能保證其未來的表現和回報，也不代表清科對其未來收益的承諾。用戶進行股票投資交易的行為具有投機性和高風險性。此外，證券產品還存在額外的特殊交易風險，包括低流動性風險、高波動率的風險、價格變化風險、出現影響價格的新聞公告等。客戶應當充分瞭解、知悉證券投資的相關風險，在註冊、開戶、交易前應當從一般理性人角度對自身的經濟實力、資金來源和風險承受能力作出客觀判斷，慎重決定投資目標、投資金額和投資產品。用戶投資行為產生的風險由客戶自行承擔，清科證券對用戶的投資風險損失不承擔任何責任。</span>
                  </li>
              </ol>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 54pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:11pt">6.2.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:宋体; font-size:11pt; font-weight:normal">免責聲明</span>
              </p>
              <ol type="a" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 48.63pt; padding-left:5.37pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">您理解並同意，清科不保證服務不會中斷，對服務的及時性、準確性不作保證。您使用清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">和本服務時所存在或導致的風險將完全由您自己承擔。清科對您不承擔任何責任，該等風險或後果包括但不限於：</span>
                  </li>
              </ol>
              <ol type="i" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 63.5pt; padding-left:17.5pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">因電腦病毒、木馬、駭客攻擊、系統不穩定、第三方服務瑕疵、政府行為及其他不可抗力等因素導致的服務中斷、數據或資料丟失、洩露或損毀以及其他的損失和風險；</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 63.5pt; padding-left:17.5pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">您或清科的電腦軟體、系統、硬體和通信線路出現故障導致的服務中斷、數據或資料丟失、洩露或損毀以及其他的損失和風險；</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 63.5pt; padding-left:17.5pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">您操作不當或通過非清科授權的方式使用清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">及本服務帶來的損失和風險；</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 63.5pt; padding-left:17.5pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">您通過清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">中的鏈接訪問第三方網站時，因第三方網站及內容所可能帶來的損失和風險；</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 63.5pt; padding-left:17.5pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">由於網路信號不穩定、網路帶寬小等原因，所引起的登入失敗、資料同步不完整、頁面打開速度慢可能帶來的風險和責任</span>
                      <span style="font-family:Aptos; font-size:11pt">;</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 63.5pt; padding-left:17.5pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">您確認及同意清科不會就有關清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">或本服務的任何性能故障、系統、伺服器或連接故障、誤差、遺漏、中斷、違反保安的行為、電腦病毒、惡意代碼、損壞、操作或傳輸上的延誤或未能存取承擔責任，即使清科已獲告知發生上述情況的可能性。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 63.5pt; padding-left:17.5pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">您確認及同意透過清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">或本服務或與其有關的通訊可能會因互聯網的開放性質或其他原因而導致中斷、訊息消失、傳輸延誤或數據傳輸失誤。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 63.5pt; padding-left:17.5pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">您理解並同意，清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">作為互聯網軟體，可能會受多種因素影響。若您下載安裝或訪問的清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">中含有病毒、木馬程式或其他惡意程式，進而威脅您的終端設備和數據安全，甚至影響清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">的正常使用，清科對此不承擔責任。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 63.5pt; padding-left:17.5pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">您確認並同意，清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">的部分產品或服務可能由第三方提供，我們將在清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">的頁面上標明或以其他方式表明。您在使用過程中，須仔細閱讀第三方的服務及產品協議、謹慎評估風險並遵守相關服務及產品協議的約定。如有疑問或發生糾紛，請與第三方協商解決，清科對此不承擔任何責任。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 63.5pt; padding-left:17.5pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">您確認並同意，若您與第三人涉及投訴等相關糾紛時，清科有權將您的主體資訊、聯繫資訊、投訴相關的事項等必要資訊提供給爭議相對方或相關部門，以保護您及其他各方的合法權益；</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 63.5pt; padding-left:17.5pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">您知悉並同意，您在使用清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:宋体; font-size:11pt">和本服務的部分功能時，將會使其他第三方獲悉您的有關資訊，清科對由此產生的任何風險和損失不承擔責任</span>
                      <span style="font-family:Aptos; font-size:11pt">;</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 68pt; padding-left:17.5pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt">如果您依據本服務提供的數據和資訊進行證券投資操作的，相關決策及投資等行為所存在的風險或損失將完全由您自己承擔，所產生的一切後果也將由您自己承擔，與清科無關，您不得由此追究清科任何責任。</span>
                  </li>
              </ol>
              <ol start="7" type="1" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt;line-height:108%; margin:0pt 0pt 0pt 13.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt; font-weight:bold">知識產權</span>
                      <ol type="1" class="awlist3" style="margin:0pt; padding-left:0pt">
                          <p style="font-family:Aptos; font-size:11pt; font-weight:normal; line-height:108%; list-style-position:inside; margin:0pt 0pt 0pt 18pt; text-indent:-36pt">
                           <span style="font-family:Aptos; font-size:11pt">7.1.</span>    
                          <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                              <span style="font-family:宋体; font-size:11pt">清科</span>
                              <span style="font-family:Aptos; font-size:11pt">APP</span>
                              <span style="font-family:宋体; font-size:11pt">內容的知識產權均由清科或相關其他權利人所有。</span>
                          </p>
                       
                      </ol>
                         <p style="font-family:Aptos; font-size:11pt; line-height:108%; list-style-position:inside; margin:0pt 0pt 0pt 18pt; text-indent:-36pt">
                              <span style="font-family:Aptos; font-size:11pt">7.2.</span>   
                              <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                              <span style="font-family:宋体; font-size:11pt">未經清科及相關權利人授權，用戶不得以任何方式和形式複製、模仿、傳播、出版、公佈、展示、使用版權相關內容，包括但不限於電子的、機械的、複印的、錄音錄影的方式和形式等，用戶亦不得將上述內容鏡像到任何其他網站或者伺服器。任何未經授權對上述內容的使用均屬違法行為，清科將依法追究法律責任。</span>
                          </p>
                  </li>
              </ol>
              
              <p style="font-size:11pt; line-height:108%; margin:0pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <ol start="8" type="1" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 13.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:宋体; font-size:11pt; font-weight:bold">適用法律及管轄</span>
                      <ol type="1" class="awlist4" style="margin:0pt; padding-left:0pt">
                          <p style="font-family:Aptos; font-size:11pt; font-weight:normal; line-height:108%; list-style-position:inside; margin:0pt 0pt 0pt 18pt; text-indent:-36pt">
                             <span style="font-family:Aptos; font-size:11pt">8.1.</span>      
                          <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                              <span style="font-family:宋体; font-size:11pt; font-weight:normal">本協議的生效、解釋、履行及糾紛解決等，均適用香港法律。</span>
                          </p>
                          <p style="font-family:Aptos; font-size:11pt; line-height:108%; list-style-position:inside; margin:0pt 0pt 0pt 18pt; text-indent:-36pt">
                             <span style="font-family:Aptos; font-size:11pt">8.2.</span>  
                              <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                              <span style="font-family:宋体; font-size:11pt">若您和清科發生任何糾紛或爭議，應先友好協商解決；若協商不成，任何一方均同意服從香港法院的管轄。</span>
                          </p>
                      </ol>
                  </li>
              </ol>
  
  
  `
  };
  