export default {
  agreementArr: [
    // {
    //   name: "免責申明",
    //   path: "/about/disclaimer",
    // },
    {
      name: "客戶協議書",
      path: "/about/agreement",
    },
    {
      name: "風險披露聲明",
      path: "/about/risk-disclosure",
    },
    {
      name: "隱私政策",
      path: "/about/privacy",
    },
    {
      name: "網上保安",
      path: "/about/security",
    },
    {
      name: "常設授權",
      path: "/about/standing-authority",
    },
    {
      name: "稅務自我證明聲明",
      path: "/about/tax-statement",
    },
    {
      name: "註冊協議",
      path: "/about/tax-register",
    },
    // {
    //   name: "客戶聲明",
    //   path: "/about/client-statement",
    // },
  ],
};
