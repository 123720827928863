export default {
    router_name: "注册协议",
    title: "注册协议",
    content: `
              <ol type="1" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; font-weight:bold; line-height:108%; margin:0pt 0pt 6pt 13.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt; font-weight:bold">确认和接受</span>
                  </li>
              </ol>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 31.5pt; text-indent:-31.5pt">
                  <span style="font-family:Aptos; font-size:11pt">1.1.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:DengXian; font-size:11pt">本协议是用户（以下有时也称</span>
                  <span style="font-family:Aptos; font-size:11pt">“</span>
                  <span style="font-family:DengXian; font-size:11pt; font-weight:bold">您</span>
                  <span style="font-family:Aptos; font-size:11pt">”</span>
                  <span style="font-family:DengXian; font-size:11pt">）与清科证券有限公司（以下称为</span>
                  <span style="font-family:Aptos; font-size:11pt">“</span>
                  <span style="font-family:DengXian; font-size:11pt; font-weight:bold">清科</span>
                  <span style="font-family:Aptos; font-size:11pt">”</span>
                  <span style="font-family:DengXian; font-size:11pt">或</span>
                  <span style="font-family:Aptos; font-size:11pt">“</span>
                  <span style="font-family:DengXian; font-size:11pt; font-weight:bold">我们</span>
                  <span style="font-family:Aptos; font-size:11pt">”</span>
                  <span style="font-family:DengXian; font-size:11pt">）之间关于使用</span>
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
                  <span style="font-family:Aptos; font-size:11pt">“</span>
                  <span style="font-family:DengXian; font-size:11pt">清科证券</span>
                  <span style="font-family:Aptos; font-size:11pt">”APP</span>
                  <span style="font-family:DengXian; font-size:11pt">（</span>
                  <span style="font-family:Aptos; font-size:11pt">“</span>
                  <span style="font-family:DengXian; font-size:11pt; font-weight:bold">清科</span>
                  <span style="font-family:Aptos; font-size:11pt; font-weight:bold">APP</span>
                  <span style="font-family:Aptos; font-size:11pt">”</span>
                  <span style="font-family:DengXian; font-size:11pt">）及其中所提供相关服务（</span>
                  <span style="font-family:Aptos; font-size:11pt">“</span>
                  <span style="font-family:DengXian; font-size:11pt; font-weight:bold">本服务</span>
                  <span style="font-family:Aptos; font-size:11pt">”</span>
                  <span style="font-family:DengXian; font-size:11pt">）所订立的协议。本服务包括但不限于提供港股股票行情资讯浏览、查询市场数据服务（以下简称</span>
                  <span style="font-family:Aptos; font-size:11pt">“</span>
                  <span style="font-family:DengXian; font-size:11pt; font-weight:bold">市场数据服务</span>
                  <span style="font-family:Aptos; font-size:11pt">”</span>
                  <span style="font-family:DengXian; font-size:11pt">）及股票交易管理等功能的平台。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 31.5pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 31.5pt; text-indent:-31.5pt">
                  <span style="font-family:Aptos; font-size:11pt">1.2.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:DengXian; font-size:11pt">在成为用户前，您务必仔细阅读以下条款，充分理解各条款内容后再选择是否接受清科证券</span>
                  <span style="font-family:Aptos; font-size:11pt">APP</span>
                  <span style="font-family:DengXian; font-size:11pt">用户协议（以下简称</span>
                  <span style="font-family:Aptos; font-size:11pt">“</span>
                  <span style="font-family:DengXian; font-size:11pt; font-weight:bold">本协议</span>
                  <span style="font-family:Aptos; font-size:11pt">”</span>
                  <span style="font-family:DengXian; font-size:11pt">）。一旦您在清科提交用户注册申请，即意味着您已阅读本协议所有条款，并对本协议条款的含义及相应的法律后果已全部通晓并充分理解，同意受本协议约束。同时，您在使用某特定服务时，该服务可能会另有单独的协议、相关业务规则等（以下统称为</span>
                  <span style="font-family:Aptos; font-size:11pt">“</span>
                  <span style="font-family:DengXian; font-size:11pt; font-weight:bold">单独协议</span>
                  <span style="font-family:Aptos; font-size:11pt">”</span>
                  <span style="font-family:DengXian; font-size:11pt">），您在使用该项服务前请阅读并同意相关的单独协议。您使用前述特定服务，即视为您接受相关单独协议。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 31.5pt; text-indent:-31.5pt">
                  <span style="font-family:Aptos; font-size:11pt">1.3.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:DengXian; font-size:11pt">清科会不时修定本协议的内容并通过</span>
                  <span style="font-family:Aptos; font-size:11pt">https://qkintl.com.hk</span>
                  <span style="font-family:DengXian; font-size:11pt">予以公布。此外，清科可能不时发布、更新的协议及各类规则、解释、声明、通告等亦为本协议不可分割的组成部分，与本协议正文具有同等的法律效力，您同样应当遵守。若您继续使用清科</span>
                  <span style="font-family:Aptos; font-size:11pt">APP</span>
                  <span style="font-family:DengXian; font-size:11pt">或本服务，则表示您已知悉、同意并接受本协议的修改或变更。若您不同意，您应立即停用清科</span>
                  <span style="font-family:Aptos; font-size:11pt">APP</span>
                  <span style="font-family:DengXian; font-size:11pt">及本服务。</span>
                  <span style="font-family:等线; font-size:11pt"></span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 31.5pt; text-indent:-31.5pt">
                  <span style="font-family:Aptos; font-size:11pt">1.4.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:DengXian; font-size:11pt">如您所属的国家或地区排除本协议的全部或部分内容，您应立即停止使用相关服务，否则，您继续使用清科</span>
                  <span style="font-family:Aptos; font-size:11pt">APP</span>
                  <span style="font-family:DengXian; font-size:11pt">或本服务视为您同意承担相关的法律风险或法律责任。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 31.5pt; text-indent:-31.5pt">
                  <span style="font-family:Aptos; font-size:11pt">1.5.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:DengXian; font-size:11pt">若本协议任何条款因任何原因部分无效或不可执行，其余条款仍有效，对您和清科均具有约束力。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <ol start="2" type="1" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; font-weight:bold; line-height:108%; margin:0pt 0pt 0pt 13.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt; font-weight:bold">协议签署</span>
                      <br/>
                      <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
                  </li>
              </ol>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:11pt">2.1.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:DengXian; font-size:11pt">用户同意本协议内容并完成注册程序后获得一个帐号，即成为清科证券</span>
                  <span style="font-family:Aptos; font-size:11pt">APP</span>
                  <span style="font-family:DengXian; font-size:11pt">的正式用户。注册成功即被视为用户确认自己具有独立的民事行为能力，明确自己所享有相应权利，能够独立承担法律责任。在注册或激活帐号、对帐号进行实名认证及使用服务的过程中，您应向清科提供您合法、真实、有效及完整的资料。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:11pt">2.2.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:DengXian; font-size:11pt">另外，请您妥善保管帐号信息及密码的安全。您需要对您的帐号及密码下的行为承担法律责任。当您怀疑有他人未经授权使用您的帐号等异常情形时，请立即通知清科。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:11pt">2.3.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:DengXian; font-size:11pt">您有权按照清科规定的销户流程申请注销帐号。如果您长期不登入账号，清科有权回收该帐号，以免造成资源浪费，由此带来的任何损失均由您自行承担。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:11pt">2.4.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:DengXian; font-size:11pt">您同意并授权清科在提供服务前和服务期间通过相关机关或组织查询或核实您的相应信息。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <ol start="3" type="1" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; font-weight:bold; line-height:108%; margin:6pt 0pt 0pt 13.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:Aptos; font-size:11pt; font-weight:bold">APP</span>
                      <span style="font-family:DengXian; font-size:11pt; font-weight:bold">的接入和使用</span>
                      <span style="font-family:等线; font-size:11pt; font-weight:bold"></span>
                      <ol type="1" class="awlist1" style="margin:0pt; padding-left:0pt">
                          <p style="font-family:Aptos; font-size:11pt; font-weight:normal; line-height:108%; list-style-position:inside; margin:0pt 0pt 0pt 18pt; text-indent:-36pt">
                              <span style="font-family:Arial; font-size:11pt">3.1.</span>
                          <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                              <span style="font-family:Aptos; font-size:11pt">APP</span>
                              <span style="font-family:DengXian; font-size:11pt">的许可</span>
                          </p>
                      </ol>
                  </li>
              </ol>
              <ol type="a" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 48.63pt; padding-left:5.37pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">清科授权您一项有限的、可撤销的、不可转让的、不可转授权的及非排他性的使用清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">的许可。您仅可为访问或使用本服务的目的而使用清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">。本协议未明示授权的其他一切权利仍由清科保留。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 49.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">本协议自你成功注册之日起生效。</span>
                  </li>
              </ol>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 54pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Aptos; font-size:11pt">3.2.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:Aptos; font-size:11pt">APP</span>
                  <span style="font-family:DengXian; font-size:11pt">的获取、安装及更新</span>
              </p>
              <ol type="a" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 48.63pt; padding-left:5.37pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">您可以直接从清科官网、软件应用商店等渠道下载清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">，也可以从得到清科授权的第三方获取。若您从未经合法授权的第三方获取清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">，清科无法保证该</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">的正常使用，亦不承担由此造成的任何不利后果。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 49.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">为了提升用户体验、完善服务内容、保证服务的安全性，清科有权随时对清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">进行更新或者对清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">的部分功能效果进行改变或限制。</span>
                  </li>
              </ol>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 54pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <ol start="4" type="1" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; font-weight:bold; line-height:108%; margin:0pt 0pt 0pt 13.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt; font-weight:bold">用户注意事项及行为规范</span>
                      <ol type="1" class="awlist2" style="margin:0pt; padding-left:0pt">
                          <p style="font-family:Aptos; font-size:11pt; font-weight:normal; line-height:108%; list-style-position:inside; margin:0pt 0pt 0pt 18pt; text-indent:-36pt">
                          <span style="font-family:Arial; font-size:11pt">4.1.</span>   
                          <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                              <span style="font-family:DengXian; font-size:11pt">用户注意事项</span>
                          </p>
                      </ol>
                  </li>
              </ol>
              <ol type="a" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 48.63pt; padding-left:5.37pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">为了向您提供有效的服务，清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">会利用您终端设备的处理器和带宽等资源。清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">使用过程中可能产生数据流量的费用，您需自行向运营商了解相关资费信息，并自行承担相关费用。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 49.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">会尽其商业上的合理努力保障您在清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">中的数据存储安全，但清科并不就此提供保证。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 48.63pt; padding-left:5.37pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">如果您停止使用清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">或帐号被回收，清科可以从服务器上永久地删除您的数据，清科并没有义务向您返还任何数据。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 49.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">您通过清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">查询到的证券资金信息由清科提供，您的实际证券资金信息仍以相应证券公司的记录为准。如您对清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">上的证券资金信息有异议，可直接联系该相应证券公司谘询。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 48.63pt; padding-left:5.37pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">在任何情况下，您不应轻信借款、索要密码或其他涉及财产的信息。涉及财产操作的，请务必事前进行核实及确认，并请经常留意相关防范诈骗犯罪的提示。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 47.41pt; padding-left:6.59pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">如您通过清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">查询他人在清科的证券资金信息，您保证已经获得了他人完整的授权。如您未获得上述授权，而导致他人资产遭受损失的，由您承担赔偿责任。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 49.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">清科可能根据实际需要对收费服务的收费标准、方式进行修改和变更；前述修改、变更或开始收费前，清科将在相应服务页面进行通知或公告。如果您不同意上述修改、变更或付费内容，则应停止使用该服务。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 49.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">您同意清科向您的手机号码、电子电邮等联系方式传送各类信息，信息传送即视为您已收悉并了解相应内容。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 46.81pt; padding-left:7.19pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">针对用户在清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">使用的“入金”、“出金”功能，清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">仅提供信息咨询、信息核对的服务。转账、汇款需根据相应商业银行的规则由您自行操作。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 46.81pt; padding-left:7.19pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">所展示的用户证券资金信息、股票持有、交易信息仅是根据清科、交易所或其他数据来源所提供的数据进行实时展示。清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">在任何时候均不是用户任何交易的提供方或担保方。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 49.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">为保证服务质量，清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">提供客服电话服务。为保证服务质量，保障服务过程及内容的可追溯性，清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">保留在客服与用户服务过程中进行全程录音的权利，清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">承诺不主动将录音内容泄露给第三方，但基于交易纠纷、技术原因及按法律要求等因素，清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">保留复制、审查录音内容的权利。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 46.81pt; padding-left:7.19pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">可能会使用第三方软件或技术，这种使用已经获得合法授权。</span>
                  </li>
              </ol>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 54pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Aptos; font-size:11pt">4.2.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:DengXian; font-size:11pt">用户责任和义务</span>
              </p>
              <ol type="a" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 48.63pt; padding-left:5.37pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">用户不得</span>
                      <span style="font-family:Aptos; font-size:11pt">:</span>
                  </li>
              </ol>
              <ol type="i" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 68pt; padding-left:13pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">利用清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">从事洗钱、窃取商业秘密、窃取其他用户个人信息等违法犯罪活动；</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 68pt; padding-left:13pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">侵入清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">传播病毒、特洛依木马等可能对清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">和清科的系统造成伤害或影响其正常运转的恶意病毒或程序；及</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 68pt; padding-left:13pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">客户不可从事非经清科同意的所有牟利性经营活动。</span>
                      <br/>
                      <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
                  </li>
              </ol>
              <ol start="2" type="a" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 49.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">若用户未遵守以上约定，清科证券有权立即中止或终止对用户提供服务，并采取一切必要措施，包括但不限于以下一项或多项：</span>
                  </li>
              </ol>
              <ol type="i" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 68pt; padding-left:13pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">警告违规用户；</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 68pt; padding-left:13pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">变更、限制或禁止违规账号部分或全部功能；</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 68pt; padding-left:13pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">暂停、限制或终止用户使用清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">的权利、注销用户账号等；及</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 68pt; padding-left:13pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">向有关监管部门或国家机关报告。</span>
                  </li>
              </ol>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 81pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <ol start="5" type="1" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; font-weight:bold; line-height:108%; margin:0pt 0pt 0pt 13.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt; font-weight:bold">市场数据服务特别约定</span>
                  </li>
              </ol>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:10pt">5.1</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:DengXian; font-size:11pt">清科</span>
                  <span style="font-family:Aptos; font-size:11pt">APP</span>
                  <span style="font-family:DengXian; font-size:11pt">提供的市场数据来自合作或对接的第三方市场数据服务商。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:10pt">5.2</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:DengXian; font-size:11pt">您同意并确认本服务提供的市场数据仅供您个人在本协议明确的授权范围内使用，不得用于其他任何目的；同时，您应采取一切合理措施，避免所接收的市场数据被用于其他未经授权或非法目的或被黑客入侵。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:10pt">5.3</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:DengXian; font-size:11pt">您同意并确认未经市场数据服务商事先的书面同意，您不得向第三方以任何方式或格式将本协议项下的市场数据或其任何部分重新分配或以其他方式传播。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:10pt">5.4</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:DengXian; font-size:11pt">您知悉并确认，市场数据及其相关的全部权利均归提供该市场数据的市场数据服务商所有。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:10pt">5.5</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:DengXian; font-size:11pt">您确认并同意，任何未经授权使用产生的一切不利后果均由您自行承担，包括但不限于应赔偿清科或市场数据服务商的损失等。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:10pt">5.6</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:DengXian; font-size:11pt">您确认并明白由于市场数据涉及金融信息，因相关立法或任何有关规范的规定，市场数据服务商及／或清科保留相关权利调整、修改上述市场数据的内容、结构等。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:10pt">5.7</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:DengXian; font-size:11pt">您确认及明白，清科须无条件的接受市场数据服务商不时提出的相关要求或因市场数据服务商的任何原因而调整或停止市场数据的提供。在发生这种情况下，清科在任何情况下均不对前述调整、中断或终止承担任何责任。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:10pt">5.8</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:DengXian; font-size:11pt">因市场数据的内容、可靠性、完整性、全面性、准确性或其他质量问题而造成的任何直接或间接损害，清科均不会承担直接或间接责任。同时，因市场数据传输导致的中断或其他问题造成的任何损害，包括间接性损失、特殊、间接或惩罚性损害，清科亦不会承担直接或间接责任。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:10pt">5.9</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:DengXian; font-size:11pt">用户如需使用清科</span>
                  <span style="font-family:Aptos; font-size:11pt">APP</span>
                  <span style="font-family:DengXian; font-size:11pt">提供的高级行情浏览、查询服务，应另行签署协议支付对应费用。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:10pt">5.10</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:DengXian; font-size:11pt">清科</span>
                  <span style="font-family:Aptos; font-size:11pt">APP</span>
                  <span style="font-family:DengXian; font-size:11pt">有权调整高级行情流量、查询服务所涉的计费方式和收费方式。用户须遵守清科证券不时公布的定价条款规则。</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <ol start="6" type="1" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; font-weight:bold; line-height:108%; margin:0pt 0pt 0pt 13.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt; font-weight:bold">风险提示及免责声明</span>
                  </li>
              </ol>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:11pt">6.1.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:DengXian; font-size:11pt">风险提示</span>
                  <span style="font-family:等线; font-size:11pt"></span>
              </p>
              <ol type="a" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 48.63pt; padding-left:5.37pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">股市有风险，投资需谨慎。任何一支股票的历史数据都不能保证其未来的表现和回报，也不代表清科对其未来收益的承诺。用户进行股票投资交易的行为具有投机性和高风险性。此外，证券产品还存在额外的特殊交易风险，包括低流动性风险、高波动率的风险、价格变化风险、出现影响价格的新闻公告等。客户应当充分了解、知悉证券投资的相关风险，在注册、开户、交易前应当从一般理性人角度对自身的经济实力、资金来源和风险承受能力作出客观判断，慎重决定投资目标、投资金额和投资产品。用户投资行为产生的风险由客户自行承担，清科证券对用户的投资风险损失不承担任何责任。</span>
                  </li>
              </ol>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 54pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <p style="font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 36pt; text-indent:-36pt">
                  <span style="font-family:Arial; font-size:11pt">6.2.</span>
                  <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                  <span style="font-family:DengXian; font-size:11pt; font-weight:normal">免责声明</span>
              </p>
              <ol type="a" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 48.63pt; padding-left:5.37pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">您理解并同意，清科不保证服务不会中断，对服务的及时性、准确性不作保证。您使用清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">和本服务时所存在或导致的风险将完全由您自己承担。清科对您不承担任何责任，该等风险或后果包括但不限于：</span>
                  </li>
              </ol>
              <ol type="i" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 63.5pt; padding-left:17.5pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">因计算机病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为及其他不可抗力等因素导致的服务中断、数据或资料丢失、泄露或损毁以及其他的损失和风险；</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 63.5pt; padding-left:17.5pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">您或清科的电脑软件、系统、硬件和通信线路出现故障导致的服务中断、数据或资料丢失、泄露或损毁以及其他的损失和风险；</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 63.5pt; padding-left:17.5pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">您操作不当或通过非清科授权的方式使用清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">及本服务带来的损失和风险；</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 63.5pt; padding-left:17.5pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">您通过清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">中的链接访问第三方网站时，因第三方网站及内容所可能带来的损失和风险；</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 63.5pt; padding-left:17.5pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">由于网络信号不稳定、网络带宽小等原因，所引起的登入失败、资料同步不完整、页面打开速度慢可能带来的风险和责任</span>
                      <span style="font-family:Aptos; font-size:11pt">;</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 63.5pt; padding-left:17.5pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">您确认及同意清科不会就有关清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">或本服务的任何性能故障、系统、服务器或连接故障、误差、遗漏、中断、违反保安的行为、计算机病毒、恶意代码、损坏、操作或传输上的延误或未能存取承担责任，即使清科已获告知发生上述情况的可能性。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 63.5pt; padding-left:17.5pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">您确认及同意透过清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">或本服务或与其有关的通讯可能会因互联网的开放性质或其他原因而导致中断、讯息消失、传输延误或数据传输失误。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 63.5pt; padding-left:17.5pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">您理解并同意，清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">作为互联网软件，可能会受多种因素影响。若您下载安装或访问的清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">中含有病毒、木马程式或其他恶意程式，进而威胁您的终端设备和数据安全，甚至影响清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">的正常使用，清科对此不承担责任。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 63.5pt; padding-left:17.5pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">您确认并同意，清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">的部分产品或服务可能由第三方提供，我们将在清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">的页面上标明或以其他方式表明。您在使用过程中，须仔细阅读第三方的服务及产品协议、谨慎评估风险并遵守相关服务及产品协议的约定。如有疑问或发生纠纷，请与第三方协商解决，清科对此不承担任何责任。</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 63.5pt; padding-left:17.5pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">您确认并同意，若您与第三人涉及投诉等相关纠纷时，清科有权将您的主体信息、联系信息、投诉相关的事项等必要信息提供给争议相对方或相关部门，以保护您及其他各方的合法权益；</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 63.5pt; padding-left:17.5pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">您知悉并同意，您在使用清科</span>
                      <span style="font-family:Aptos; font-size:11pt">APP</span>
                      <span style="font-family:DengXian; font-size:11pt">和本服务的部分功能时，将会使其他第三方获悉您的有关信息，清科对由此产生的任何风险和损失不承担责任</span>
                      <span style="font-family:Aptos; font-size:11pt">;</span>
                  </li>
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 68pt; padding-left:17.5pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt">如果您依据本服务提供的数据和信息进行证券投资操作的，相关决策及投资等行为所存在的风险或损失将完全由您自己承担，所产生的一切后果也将由您自己承担，与清科无关，您不得由此追究清科任何责任。</span>
                  </li>
              </ol>
              <ol start="7" type="1" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt;  line-height:108%; margin:0pt 0pt 0pt 13.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt; font-weight:bold">知识产权</span>
                      <ol type="1" class="awlist3" style="margin:0pt; padding-left:0pt">
                          <p style="font-family:Aptos; font-size:11pt; font-weight:normal; line-height:108%; list-style-position:inside; margin:0pt 0pt 0pt 18pt; text-indent:-36pt">
                          <span style="font-family:Arial; font-size:11pt">7.1.</span>   
                          <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                              <span style="font-family:DengXian; font-size:11pt">清科</span>
                              <span style="font-family:Aptos; font-size:11pt">APP</span>
                              <span style="font-family:DengXian; font-size:11pt">内容的知识产权均由清科或相关其他权利人所有。</span>
                          </p>
                          <p style="font-family:Aptos; font-size:11pt; line-height:108%; list-style-position:inside; margin:0pt 0pt 0pt 18pt; text-indent:-36pt">
                          <span style="font-family:Arial; font-size:11pt">7.2.</span>    
                          <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                              <span style="font-family:DengXian; font-size:11pt">未经清科及相关权利人授权，用户不得以任何方式和形式复制、模仿、传播、出版、公布、展示、使用版权相关内容，包括但不限于电子的、机械的、复印的、录音录像的方式和形式等，用户亦不得将上述内容镜像到任何其他网站或者服务器。任何未经授权对上述内容的使用均属违法行为，清科将依法追究法律责任。</span>
                          </p>
                      </ol>
                  </li>
              </ol>
              <p style="font-size:11pt; line-height:108%; margin:0pt">
                  <span style="font-family:Aptos; font-size:11pt">&#xa0;</span>
              </p>
              <ol start="8" type="1" style="margin:0pt; padding-left:0pt">
                  <li style="font-family:Aptos; font-size:11pt; line-height:108%; margin:0pt 0pt 0pt 13.25pt; padding-left:4.75pt; text-indent:0pt">
                      <span style="font-family:DengXian; font-size:11pt; font-weight:bold">适用法律及管辖</span>
                      <ol type="1" class="awlist4" style="margin:0pt; padding-left:0pt">
                          <p style="font-family:Aptos; font-size:11pt; font-weight:normal; line-height:108%; list-style-position:inside; margin:0pt 0pt 0pt 18pt; text-indent:-36pt">
                          <span style="font-family:Arial; font-size:11pt">8.1.</span>   
                          <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                              <span style="font-family:DengXian; font-size:11pt; font-weight:normal">本协议的生效、解释、履行及纠纷解决等，均适用香港法律。</span>
                          </p>
                          <p style="font-family:Aptos; font-size:11pt; line-height:108%; list-style-position:inside; margin:0pt 0pt 0pt 18pt; text-indent:-36pt">
                          <span style="font-family:Arial; font-size:11pt">8.2.</span>   
                          <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;</span>
                              <span style="font-family:DengXian; font-size:11pt">若您和清科发生任何纠纷或争议，应先友好协商解决；若协商不成，任何一方均同意服从香港法院的管辖。</span>
                          </p>
                      </ol>
                  </li>
              </ol>
  
     `
  };
  