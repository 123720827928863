<template>
  <div id="app">
    <transition name="slide-up">
      <div v-if="showBanner" class="cookie-banner">
        {{ $t("home.cookies") }}
        <a href="/#/privacy-policy" class="privacy-link">
          {{ $t("home.cookiesLink") }}</a
        >
        <span class="close-btn" @click="closeBanner">×</span>
      </div>
    </transition>
    <router-view :key="refresh" />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import api from "@/api";
export default {
  data() {
    return {
      showBanner: false
    };
  },
  computed: {
    refresh() {
      return this.$i18n.locale || "zh";
    },
    ...mapGetters(["userInfo"])
  },
  // watch: {
  // ["userInfo.token"](val) {
  //   if (val) this.init();
  // },
  // },
  mounted() {
    this.init();
    if (localStorage.getItem("cookieBannerClosed") === "true") {
      this.showBanner = false;
    } else {
      this.showBanner = true;
    }
  },
  methods: {
    closeBanner() {
      this.showBanner = false;
      localStorage.setItem("cookieBannerClosed", "true"); // 记录已关闭状态
    },
    init() {
      this.getUserInfo();
      this.getUserAccountList();
    },
    ...mapMutations("user", ["SET_USER_ACCOUNT_LIST", "SET_USER_INFO"]),
    getUserInfo() {
      if (!this.userInfo.token) return;
      api.user.v2UserInfo().then((res) => {
        this.SET_USER_INFO({ ...this.userInfo, ...res });
      });
    },
    getUserAccountList() {
      if (!this.userInfo.token) return;
      api.user.userTradeAccountList().then((res) => {
        this.SET_USER_ACCOUNT_LIST(res);
      });
    }
  }
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #d0ebff;
  color: #333;
  text-align: center;
  padding: 15px;
  font-size: 14px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.privacy-link {
  color: #0056b3;
  text-decoration: none;
  margin-left: 5px;
}

.privacy-link:hover {
  text-decoration: underline;
}

.close-btn {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  cursor: pointer;
}

.slide-up-enter-active {
  animation: slideUp 0.5s ease-in-out;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

/* Vue 过渡动画 */
.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

.slide-up-enter-to,
.slide-up-leave-from {
  transform: translateY(0);
  opacity: 1;
}
</style>
